import {Component, OnInit} from '@angular/core';
import {jwtDecode} from "jwt-decode";
import {LoggedUser} from "./auth/interfaces/logged-user";
import {AuthService} from "./auth/services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit{

  loggedUser: LoggedUser | null = null;

  constructor(private authService: AuthService) {
  }

  decodeToken() {
    this.authService.getTokenNoAsync().then(token => {
      this.loggedUser = jwtDecode(token);
      this.authService.setLoggedUserOnLocalStorage(token);
    });
  }

  ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUserLocalStorage();
    if (!this.loggedUser){
      this.decodeToken();
    }
  }
}
