import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'services',
    pathMatch: 'full'
  },
  {
    path: 'services',
    loadChildren: () => import('./desp-service/desp-service.module').then(m => m.DespServiceModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard],
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'tags',
    loadChildren: () => import('./service-tags/service-tags.module').then(m => m.ServiceTagsModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'variables',
    loadChildren: () => import('./variables/variables.module').then(m => m.VariablesModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'spatialExtents',
    loadChildren: () => import('./spatial-extents/spatial-extents.module').then(m => m.SpatialExtentsModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'formats',
    loadChildren: () => import('./formats/formats.module').then(m => m.FormatsModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'crss',
    loadChildren: () => import('./crs/crss.module').then(m => m.CrssModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'projections',
    loadChildren: () => import('./projections/projection.module').then(m => m.ProjectionModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'sensors',
    loadChildren: () => import('./sensors/sensor.module').then(m => m.SensorModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'dataTypes',
    loadChildren: () => import('./data-types/data-type.module').then(m => m.DataTypeModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'dataTags',
    loadChildren: () => import('./data-tags/data-tag.module').then(m => m.DataTagModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: 'treeNodes',
    loadChildren: () => import('./tree-nodes/tree-nodes.module').then(m => m.TreeNodesModule),
    canActivate: [AuthGuard],
    canMatch: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'services'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
