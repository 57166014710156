import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanMatch,
  GuardResult,
  MaybeAsync, Route,
  Router,
  RouterStateSnapshot, UrlSegment
} from "@angular/router";
import {AuthService} from "../auth/services/auth.service";
import {Roles} from "../shared/helper/role";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanMatch{

  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    const role = this.authService.getRoleFromLocalStorage();
    if (role) {
      return role === Roles.ADMIN || role === Roles.USER;
    }
    return false;
  }

  canMatch(route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> {
    const role = this.authService.getRoleFromLocalStorage();
    if (role === Roles.ADMIN || segments.length <= 1){
      return true;
    }
    if (!segments.some(segment => segment.path === 'list')){
      this.router.navigateByUrl('/services/list');
    }
    return true;
  }
}
