import { KeycloakConfig } from 'keycloak-js';
import {environment} from "../../../environments/environment";

const keycloakConfig: KeycloakConfig = {
  url: environment.keycloakUrl,
  realm: environment.realm,
  clientId: environment.clientId
};

export default keycloakConfig;
